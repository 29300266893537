@media (max-width: 1800px) {
	html,
	body {
		font-size: 18px;
	}
}
@media (max-width: 1100px) {
	html,
	body{
		font-size: 15px;
	}
}
@media (max-width: 1000px) {

	html,
	body {
		font-size: 10px;
	}
}
@media (min-width: 370px) and (max-width: 1000px) {
	html,
	body {
		font-size: 11px;
	}
}
@media (min-width: 425px) and (max-width: 1000px) {

	html,
	body {
		font-size: 12.5px;
	}
}
@media (min-width: 650px) and (max-width: 1000px) {

	html,
	body {
		font-size: 14.5px;
	}
}

:root{
	--languages: 5;
	--brownishYellow: #371D10;
	--lightYellow: #E3CC8F;
	--yellow:#AE9560;
	--buttonBorders: #4e3112;

	--locationButtonBorder: #4e3112;
	--locationButtonSVGFill: #7b5622;

	--rangeZoomBackgroundColor: #130a00;
	--rangeZoomBorderColor: #4e3112;
	--rangeZoomButtonColor: #7b5622;
	--allMainSVGsFill:#7b5622;
	--backgroundButtonContainers: #110900;
	--backgroundButtonContainersHover: #1f1100;
	--backgroundButtonContainersListHover: #4e311292;

	--countryStatsHeaderColor: #8a6736;
	--countryStatsBodyColor:#AE9560;


	--containersBackground: #0e0700;
	--locationButtonShadow: #E3CC8F;
}


.preview-wrapper svg path{
	stroke: var(--locationButtonBorder);
	stroke-width: 0.3rem;
}
@media (max-width: 1000px) {
	.preview-wrapper svg path {
		stroke: var(--locationButtonBorder);
		stroke-width: 0.6rem;
	}
}


*
{
	/* cursor: none; */
    margin: 0;
    padding: 0;
	 box-sizing: border-box;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-tap-highlight-color: transparent;
}

/* width */
::-webkit-scrollbar {
	width: 0.8rem;
}

@media (max-width: 1000px) {
	::-webkit-scrollbar {
			width: 2px;
		}
}
/* Track */
::-webkit-scrollbar-track {
	background: var(--containersBackground);
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: var(--countryStatsHeaderColor);
	cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #6f5128;
}
html,
body
{
    overflow: hidden;
	 /* color: #ffffff; */
	font-family: 'Inter', sans-serif;
}
@media (max-width: 1000px) {
	html,
	body {
		background-color: var(--containersBackground);
	}
}
.webgl
{
    position: fixed;
    top: 0;
    left: 0;
	 /* height: 70rem; */
    outline: none;
	 z-index: -1;
}
/* @media (max-width: 1000px) {
	html,
	body {
		background: var(--containersBackground);
	}
	.webgl{
		/* top: 5.2rem; */
		/* bottom:10rem; */
	/* } */
/* }  */
svg.allMainSVGs path{
 fill: var(--allMainSVGsFill);
}
	
/* preview */
.preview-wrapper{
	position:fixed;
	top: 0;
	left:0;
	height: 100%;
	width: 100%;
	background-color:var(--containersBackground);
	z-index:1000;
	opacity: 1;
	transition: opacity 1s ease;
}
.fade-out {
	opacity: 0;
}

.country-searcher_wrapper,
.zoom_wrapper,
.zoomRange_wrapper{
	opacity: 1;
	transition: opacity 3s ease;
}


.fade-in {
	opacity: 1;
}
.preview-container{
	text-align: center;
	width: 100%;
	height: 100%;
	/* display: flex; */
	/* justify-content: center; */
	/* align-items: center; */
}
.text-container{
	position: absolute;
	top: 40%;
	left: 50%;
	transform: translate(-50%,-50%);
	z-index: 1000;
}
.previewExploreButton{
	height: 2.4rem;
	width: 100%;
}
.preview-container button{
	/* margin-top: 5rem; */
	font-family: 'Kameron', serif;
display: none;
	color: var(--locationButtonSVGFill);
	height: auto;
	width: auto;
	border: 0.13rem solid var(--buttonBorders);
	font-size: 1rem;
	letter-spacing: 0.2rem;
	padding: 0.5rem 1.8rem ;
	background-color: var(--containersBackground);
	cursor: pointer;
	opacity: 0;
	transition: opacity 3s ease, background-color 0.3s ease;
	/* box-shadow: 0px 0px 5px var(--lightYellow); */
	animation: buttonCallToAction 2.5s infinite;
}
.slide-nav-buttons .nextButton{
	animation: nextbuttonCallToAction 2.5s infinite;
}





@keyframes nextbuttonCallToAction {
	0% {
		box-shadow: inset 0px 0px var(--rangeZoomButtonColor);
	}

	50% {
		box-shadow: inset 0px 0px 1.6rem var(--rangeZoomButtonColor);
	}

	100% {
		box-shadow:inset 0px 0px var(--rangeZoomButtonColor);
	}
}

@keyframes buttonCallToAction {
	0% {
		box-shadow: 0px 0px 0px var(--rangeZoomButtonColor);
	}
	50%{
		box-shadow: 0px 0px 0.9rem var(--rangeZoomButtonColor);
	}
	100% {
		box-shadow: 0px 0px 0px var(--rangeZoomButtonColor);
	}
}
/* .preview-container button.hidden {
	display: none;
	opacity: 0;
	transition: all 3s ease;
} */
.preview-container button:hover {
	background-color: var(--backgroundButtonContainersHover);
	/* transition: all 0.3s ease; */
}

.preview-wrapper a {
	text-decoration: none;
	color: var(--locationButtonSVGFill);
	letter-spacing: 0.09rem;
	transition: text-decoration 0.3s ease;
}
.preview-wrapper a:hover {
	text-decoration: underline;
	color: var(--locationButtonSVGFill);
	transition: text-decoration 0.3s ease;
}
.preview-wrapper .footer{
	width: 100%;
	text-align: center;
	font-family: 'Kameron', serif;
	font-size: 1rem;
	color: var(--locationButtonSVGFill);
	opacity: 0.6;
	position: fixed;
	bottom: 2%;
	font-weight: 600;
	letter-spacing: 0.06rem;	
	left: 50%;
	transform: translateX(-50%);
	z-index: 1000;
}
@media (max-width: 1000px) {
	.preview-wrapper .footer {
		font-size: 1.2rem;
	}
}
.intro-text{
	text-align: center;
}
.intro-text p{
	font-family: 'Kameron', serif;
	font-size: 1rem;
	letter-spacing: 0.15rem;
	width: 30rem;
	color: var(--locationButtonSVGFill);
	margin: 1.5rem auto 3rem;
}
.logo-loading h1{
	margin-block-start: 0rem;
	margin-block-end: 0rem;
	font-family: 'Kameron',serif;
	font-weight: 700;
	font-size: 6rem;
	letter-spacing: 1.6rem;
	text-transform: uppercase;
	color: var(--containersBackground);
	-webkit-text-stroke-width: 0.13rem;
	-webkit-text-stroke-color: var(--locationButtonSVGFill);
	text-shadow: 0px 0px 1rem var(--locationButtonSVGFill);
}


.firstBigLine{
	stroke-dashoffset: 2610;
	stroke-dasharray: 2610;
	animation: draw 10s forwards;
}
.secondBigLine {
	stroke-dashoffset: 3010;
	stroke-dasharray: 3010;
	animation: draw 10s forwards;
}
.svgShortLine{
	stroke-dashoffset: 510;
	stroke-dasharray: 510;
	animation: draw 2s 6s forwards;
}

.contour-svgs {
	width: 110%;
	position: absolute;
	left: -5%;
}

@media (min-width: 1400px) {
	.contour-svgs {
		width: 90%;
		position: absolute;
		left: 5%;
	}
}


.contour-svgs svg {
	width: 100%;
}

.theTopWorldContourSvg {
	top: 0%;
}

.theBottomWorldContourSvg {
	bottom: 6%;
}
@media (min-width: 1001px) and (max-width: 1350px) {
	.logo-loading h1 {
		font-size: 5.5rem;
	}

	.intro-text p {
		font-size: 1rem;
		margin: 1rem auto 2rem;
	}

	.theBottomWorldContourSvg {
		bottom: 6%;
	}
		.contour-svgs {
			width: 110%;
			position: absolute;
			left: -5%;
		}
}
@media (max-height: 700px) and (min-width: 1200px) {
	.logo-loading h1 {
	
		font-size: 5rem;

	}
	.contour-svgs {
		width: 90%;
		position: absolute;
		left: 5%;
	}

	.intro-text p {
		font-size: 1rem;
		margin: 1rem auto 2rem;
	}

	.theBottomWorldContourSvg {
		bottom: 1%;
	}
}
@keyframes draw {
	to {
		stroke-dashoffset: 0;
	}

}
@media (max-width: 1000px) {
	.preview-container {
		width: 100%;
		overflow: hidden;
	}

	.preview-container button {
		font-size: 1.55rem;
		padding: 0.6rem 2.6rem;
	}
	.countrySvg {
		margin-top: 5.8rem;
	}
	.intro-text p {
		width: 90%;
		font-size: 1.4rem;
		margin: 3rem auto 3.5rem;
	}

	.logo-loading h1 {
		margin-top: 0rem;
		font-size: 5rem;
		letter-spacing: 0.9rem;
	}


.contour-svgs {
	width: 110%;
	position: absolute;
	left: -5%;
}

.contour-svgs svg {
	width: 100%;
	scale: 1.9;
}

.theTopWorldContourSvg {
	top: 10%;
}

.theBottomWorldContourSvg {
	bottom: 15%;
}
}
@media  (max-width: 1000px) and (orientation: landscape) {
	.contour-svgs {
		display: none;
	}
}

@media (min-width: 1400px){
	/* .logo-loading h1 {
		margin-top: 0rem;
		font-size: 6rem;
		letter-spacing: 0.9rem;
	} */
	.theBottomWorldContourSvg {
		bottom: 1%;
	}
}

.countryStats_wrapper{
	position: fixed;
	opacity: 1;
	left:0;
	top: 0;
	width: 100%;
	height:100%;
	z-index: 100;
	transition: opacity 1.5s ease;
}
.countryStats_wrapper.hidden {
	display: block;
	opacity: 0;
	z-index: -100;
	transition: opacity 1.5s ease;
}
/* .countryStats_wrapper.hidden{
	opacity: 0;
	display: block;
	left: -100vw;
	transition: all 1.5s ease;
} */
/* .countryStats_wrapper.hidden .info-container div{
	margin-left: -1rem;
		transition: all 2s ease;
}
.countryStats_wrapper .info-container div{
	margin-left: 0rem;
		transition: all 2s ease;
} */


@media (max-width: 1000px) {
	.countryStats_wrapper {
		opacity: 1;
	z-index: 100;
	transition: all 2.5s ease;
	}
	.countryStats_wrapper.hidden {
		opacity: 0;
		display: block;
		top: -100vh;
		transition: all 2s ease;
		z-index: 100;
	}
}
.closeIcon{
	position: absolute;
	/* background-color: var(--containersBackground)9f; */
	padding: 0.5rem;
	top: 1.5rem;
	right: 1.5rem;
	cursor: pointer;
	height: 3rem;
	width: 3rem;
}
.closeIcon svg path{
	fill: var(--countryStatsHeaderColor);
}
.hidden{
	display: none;
}
/* .countryStats_wrapper.hidden .countryStats_container{
left: -100%;

} */
.countryStats_container{
	z-index: 100;
	/* left: 0%; */
	width: 100%;
	height: 100%;
	display:flex;
}

@media (max-width: 1000px) {

	.countryStats_container {
			display: flex;
			flex-direction: column;
		}
}

.countryStats-info{

	/* width: 38.6rem; */
	width: 55%;
	background-color:var(--containersBackground) ;
	color: var(--countryStatsBodyColor);
	overflow-y: auto;
	scrollbar-color: var(--countryStatsHeaderColor) var(--containersBackground);
}
.countryStats-visualization {
	
	width: 45%;
	height: 100%;
	background-color: #04090d2a;
}
@media (max-width: 1000px) {

	.countryStats-info {
			width: 100%;
		}
	.countryStats-visualization {
			width: 0%;
			height: 0%;
			/* background-color: #04090d2a; */
	}
}
.info-container{
	width: 34rem;
	margin: 2.5rem auto 0;
}
.country-header{
	text-align: center;
}
.countryName{
	text-transform: uppercase;
	font-weight: 700;
	font-size: 1.6rem;
	letter-spacing: 0.4rem;
	margin-bottom: 0.5rem;
	color: var(--countryStatsHeaderColor);
}
.country-abbreviation{
	color: var(--yellow);
	font-size: 1rem;
	margin-bottom: 2rem;
	font-weight: 300;
}
.grid-row{
	display: grid;
	grid-template-columns: 1fr 0.8fr 1fr;
	margin-bottom: 1.5rem;
	align-items: center;
}
@media (max-width: 1000px) {
	.info-container {
		width: 31rem;
		margin: 2.5rem auto 0;
	}
	.latitude-header.info-section-header,
	.longitude-header.info-section-header{
		font-size: 1.3rem;
		color: var(--lightYellow);
	}
	.grid-row {
	grid-template-columns: 1fr 0.3fr 1fr;
	}
	.countryName {
		font-size: 1.9rem;
		/* margin-top: 1rem; */
	}
	.country-abbreviation {
		font-size: 1.3rem;
	}
	.info-section-header{
		font-size: 1.5rem;
	}
	.grid-name,
	.grid-data{
		font-size: 1.3rem;
	}
	.grid-line {
		height: 0.1rem;
	}
	.geographic-coordinates {
		flex-direction: column;	
		/* width: 30rem; */
	}
	

}
.grid-line{
	height: 1px;
	width: auto;
	background-color: var(--yellow);
}
.grid-data{
	margin-left: 3rem;
	line-height: 1.5rem;
}
.info-section-header{
	color: var(--countryStatsHeaderColor);
	text-align: center;
	margin-bottom: 2rem;
	margin-top: 3rem;
	font-weight: 300;
}
.longitude-header , .latitude-header{
	margin-top: 0;
}
.geographic-coordinates{
	display: flex;
	justify-content: space-between;
}
/* .latitude-header{
	margin-left: -1rem;
} */
.latitude{
	width: 14.6rem;
}
.longitude{
	width: 14.6rem;
}
.latitude .grid-data{
	margin-left: 1.6rem;
}
.longitude .grid-data {
	margin-left: 1.6rem ;
}
@media (max-width: 1000px) {
.longitude,
.latitude {
	width: 100%;
	margin: 0 auto;
}
.longitude .grid-data,
.latitude .grid-data {
	margin-left: 3rem;
}
.longitude .grid-name,
.latitude .grid-name {
	padding-left: 5rem;
}
.longitude.grid-container{
	padding-left: 0;
}

}
.languages_container.grid-row{
	grid-template-columns: 1fr 0.8fr 1fr;
	/* grid-template-rows: 1fr 1fr 1fr; */
}

@media (max-width: 1000px) {
	.languages_container.grid-row {
			grid-template-columns: 1fr 0.3fr 1fr;
		}
		
}
.languages_container .grid-name{
	grid-row: 1 / var(--languages);
}
.languages_container .grid-line, .languages_container .grid-data{
	margin-top: 1rem;
}
.metric{
	position: relative;
}
.metric span{
	display: block;
	position: absolute;
	top: -0.5rem;
	right: -0.5rem;
	font-size: 0.6rem;
}

.flag img{
	width: 8rem;
	
	box-shadow: 0rem 0rem 3rem var(--buttonBorders) ;
}
.elevation_parent div{
	font-size: 0.75rem;
	/* color: var(--yellow); */
}

.country-searcher_wrapper{

	position: relative;
	z-index: 10;
	/* color: AE9560; */
	width: 30rem;
	position: fixed;
	left: 50%;
	transform: translateX(-50%);
}


.country-searcher_container{
	width: 30rem;
	padding-top: 1rem;
	margin: 0 auto;
	
}
@media (max-width: 1000px) {
.country-searcher_wrapper {

	width: 101%;
	
}
	.elevation_parent div {
		font-size: 0.9rem;
		/* color: var(--yellow); */
	}
	.country-searcher_container {
		padding-top: 0rem;
	}
}

.country-searcher_container .input-container{
	position: relative;
	width: 100%;
	height: 100%;
}
.voidFormContainer {
	cursor: text;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background-color: rgba(255, 255, 255, 0);
	/* background-color: red; */
}
.voidFormContainer:hover{
	background-color: #130a0036;
}
.country-searcher_container svg{
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 2.8rem;
	padding-left: 0.6rem;
	padding-right: 0.3rem;
	height: 100%;
}

.country-searcher_container svg.search{
	cursor: pointer;
	/* left: 0.7rem; */
	/* z-index: 1; */
	left: 0;
}
.country-searcher_container svg.removeValue {
	top: 49%;
	right: 0;
	width: 2.4rem;
	padding-left: 0.6rem;
	padding-right: 0.6rem;
	cursor: pointer;
}
.country-searcher{
	/* margin: 0 auto; */
	/* display: block; */
	position: relative;
	color: var(--allMainSVGsFill);
	width: 100% ;
	text-align: center;
	background-color: var(--backgroundButtonContainers);
	border: 0.2rem solid var(--buttonBorders);
	/* border: 0.2rem solid #422401; */
	outline: none;
	padding: 0.75rem 2.5rem 0.75rem 3rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 1.2rem;
	letter-spacing: 0.2rem;
	background-image: linear-gradient(105deg, var(--buttonBorders) 0%, var(--buttonBorders) 96%, transparent 96%);
	background-position: -30rem 0;
	background-size: cover;
	background-repeat: no-repeat;
	transition: all 0.3s ease ;
}
@media (max-width: 1000px) {
	.country-searcher_wrapper {
		padding: 0.85rem 0;
		background-color: var(--backgroundButtonContainers);
	}
	.country-searcher_container {
		width: 30rem;
	}
	.country-searcher {
		background-position: -30rem 0;
		padding: 0.75rem 2.8rem;
	}
}

/* .country-searcher.progressing{
	animation: progressBar 1.5s linear infinite;
}
@keyframes progressBar {
	0%{
		background-position: -30rem 0;
	}@media (max-width: 1000px) {
		100%{
			background-position: 0 0;
	}
} */
.country-searcher:hover {
	background-color: var(--backgroundButtonContainersHover);
}
ul{
	list-style: none;
	z-index: 10;
}
.country-searcher-results_container.hidden {
	display: block;
	max-height: 0rem;
	border-bottom: 0rem solid var(--buttonBorders);
	/* border-left: 0rem solid var(--buttonBorders); */
	transition: max-height 0.3s ease, border-left 3s ease;
}
.country-searcher-results_container{
	overflow-y: auto;
	scrollbar-color: var(--countryStatsHeaderColor) var(--containersBackground);
	max-height: 20rem;
	color: var(--yellow);	
	border-bottom: 0.2rem solid var(--buttonBorders);
	/* border-left: 0.2rem solid var(--buttonBorders); */
	transition: max-height 0.5s ease;

	/* border-right: 0.2rem solid var(--yellow); */
	/* z-index: -100000; */
	/* display: none; */
}

.country-searcher-results_container li{
	background-color: var(--backgroundButtonContainers);
	padding: 0.7rem 1.2rem ;
	border-left: 0.14rem solid var(--buttonBorders);
	border-right: 0.14rem solid var(--buttonBorders);
	border-bottom: 0.14rem solid var(--buttonBorders);
	font-size: 1.2rem;
	cursor: pointer;
}
@media (max-width: 1000px) {
	
	.country-searcher-results_container.hidden {
		display: block;
		max-height: 0vh;
		border-bottom: 0rem solid var(--buttonBorders);
		transition: all 0.4s ease;
	}
	.country-searcher-results_container {
		display: block;
		max-height: 26rem;
		bottom: 0;
		border-bottom: 0.2rem solid var(--buttonBorders);
		transition: all 0.8 ease;

	}

.country-searcher {
	/* height:5rem; */
	font-size: 1.5rem;
	padding: 1rem 1rem 1rem 2.5rem;
}
.country-searcher-results_container li {

	font-size: 1.6rem;
	padding: 1rem 1.2rem;
}
.country-searcher_container svg {
	width: 3rem;
	padding-left: 0.8rem;
}
.country-searcher_container svg.removeValue {
	width: 2.8rem;
	/* padding-left: 0.6rem; */
	padding-right: 0.8rem;
}
}
.country-searcher-results_container li:hover {
	background-color: var(--backgroundButtonContainersListHover);
}
.void-container{
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	/* background-color: red; */
	z-index: -1;
}
.void-container-the-second{
		position: fixed;
		top: 0;
		left: 0;
		height: 100vh;
	background-color: rgba(255, 0, 0, 0.515);
		 width: 100vw;
		z-index: -1;
}
/* .cursor{
	width: 3rem;
	height: 3rem;
	border-radius: 100%;
	border: 0.1rem solid var(--yellow);
	position: fixed;
	pointer-events: none;
	left: 0;
	top: 0;
	transform: translate(calc(-50% + 1rem ), -50%);
	transition: all 200ms ease-out;
}
.cursor2{
	width: 1rem;
	height: 1rem;
	border-radius: 100%;
	background-color: var(--lightYellow);
	opacity: 0.3;
	position: fixed;
	transform: translate(-50%, -50%);
	pointer-events: none;
	transition: width 0.3s, height 0.3s, opacity 0.3s;
}
.clicked{
	width: 2.4rem;
	height: 2.4rem;
	opacity: 0.5s;
} */

.zoom_wrapper{
	display: block;
	position:fixed;
	bottom: 1.5rem;
	width: 100%;
	/* left:50%; */
	/* transform:translateX(-50%); */
	/* z-index: 1; */
}
.zoom_wrapper.hidden {
	display: none !important;
}
@media (max-width: 1000px) {

	.zoom_wrapper {
		opacity: 1;
		display: flex;
		justify-content: center;
		bottom: 0;
		padding: 0.85rem 0;
		background-color: var(--backgroundButtonContainers);
		transition: opacity 1s ease;
	}
	
	.wrapper_container {
		display: flex;
		margin: 0 auto;
		gap: 0.7rem;
	}
	.zoom_wrapper.noControls{
		opacity: 0;
		transition: opacity 1s ease;
	}
	
}
.zoom_container {
	height: 2.7rem;
	width: 10rem;
	margin: 0 auto;
	display: flex;
	/* background-color: red; */
	/* border: 2px solid var(--yellow); */
	/* justify-content: space-around; */
	text-align: center;
	/* gap: 1rem; */
	
}
@media (max-width: 1000px) {
	.zoom_container {
		margin: 0;
		gap: 0.5rem;
	}
	
}
button.zoom{
	width: 5rem;
	height: auto;
	cursor: pointer;
	background-color: var(--backgroundButtonContainers);
	border: 2px solid var(--buttonBorders);
	display: flex;
	justify-content: center;
	align-items: center;
}
button.zoom svg{
	height: 1.7rem;
	
}
@media (max-width: 1000px) {
button.zoom {
	height: 5rem;
	width: 5rem;
	padding: 0.5rem;
	border: 0.2rem solid var(--buttonBorders);
}
button.zoom svg {
	height: 5rem;
	width: 5rem;
}
}
.zoomIn{
	border-right: 0.1rem solid var(--buttonBorders);
}
.zoomOut {
	border-left: 0.1rem solid var(--buttonBorders);
}
@media (max-width: 1000px) {
	.zoomIn {
			border-right: 0.2rem solid var(--buttonBorders);
		}
	.zoomOut {
		border-left: 0.2rem solid var(--buttonBorders);
	}
}

.zoomRange_wrapper{
	position: fixed;
	right: -8rem;
	top: 50%;
	padding: 0.5rem;
	transform: translateY(-50%) rotate(-90deg);
	/* background-color: var(--containersBackground); */
	/* width: 2rem;  */
	
	/* display: flex;
	align-items: center;
	justify-content: center; */
}
.zoomRange_container input{ 
	
	border: 0.14rem solid var(--rangeZoomBorderColor);
	border-right: 0rem;
	border-left: 0rem;
	-webkit-appearance: none;
	transform:  rotate(0deg);
	cursor: pointer;
	width: 20rem;
	background: var(--rangeZoomBackgroundColor);
	border-radius: 3rem;
	outline: none;
	opacity: 1;
	-webkit-transition: .2s;
	transition: opacity .2s;
	overflow: hidden;
} 


.zoomRange_container input::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 1.5rem;
	height: 1.5rem;
	background: var(--rangeZoomButtonColor);
	border-radius: 100%;
	cursor: pointer;
	box-shadow: 10.5rem 0 0 10rem #000000;
}
.zoomRange_container input::-moz-range-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 1.25rem;
	height: 1.25rem;
	background: var(--rangeZoomButtonColor);
	border-radius: 100%;
	cursor: pointer;
	border: none;
	outline: none;
	box-shadow: 10.5rem 0 0 10rem #000000;
}
@media (max-width: 1000px) {
	.zoomRange_wrapper {
		right: -13.6rem;
	}
	.zoomRange_container input {
		width: 30rem;
	}
	.zoomRange_container input::-webkit-slider-thumb {
		width: 2.5rem;
		height: 2.5rem;
		box-shadow: 21rem 0 0 20rem #000000;
	}
	.zoomRange_container input::-moz-range-thumb {
		width: 2rem;
		height: 2rem;
	}
}
/* .zoomRange_container input::-webkit-slider-runnable-track{
	background-color: var(--containersBackground);
} */
/* .zoomRange_container input::-moz-range-thumb {
	width: 25px;
	height: 25px;
	border-radius: 3rem;
	background: #04AA6D;
	cursor: pointer;
} */

.screenStatus{
	position: fixed;
	top: 50%;
	transform: translateY(-50%);
	left: 1rem;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	z-index: 11;
}
@media (max-width: 1000px) {
	.screenStatus{
		flex-direction: row-reverse;
		position: relative;
		top: 0%;
		transform: none;
		left: 0rem;
	}
}
/* .fullscreen{
	margin-bottom: 0.5rem;
} */
.screenIco{
	width: 3.5rem;
	height: 3.5rem;
	border: 0.2rem solid var(--buttonBorders);
	padding: 0.5rem;
	background-color: var(--backgroundButtonContainers);
	cursor: pointer;
}
.void{
	display: none;
}
.locationButton button.hidden{
	display: none;
}

.locationButton button{
	display: flex;
	justify-content: center;
	align-items: center;
	border: 0.2rem solid var(--locationButtonBorder);
	padding: 0;
	background-color: #160b00;
}
.locationButton svg path{
	fill: var(--locationButtonSVGFill);
}
.locationButton svg {
	width: 150%;
}
svg path.shadow{
	-webkit-filter: drop-shadow(0 0 0rem var(--locationButtonShadow));
	filter: drop-shadow(0 0 0rem var(--locationButtonShadow));
}

svg path.shadowOne{
	-webkit-filter: drop-shadow(0 0 0rem var(--locationButtonShadow));
			  filter: drop-shadow(0 0 0rem var(--locationButtonShadow));
}
svg.currentIntersected path.shadow {	
	animation: svgCurrentIntersected 2.5s infinite;
}

	

/* burger strokes */
.burgerButton svg path{
	stroke: var(--allMainSVGsFill);
}

button.fullscreen path.enterfullscreen {
	fill: var(--allMainSVGsFill);
}

button.fullscreen path.exitfullscreen {
	fill: #7b562200;
}

button.fullscreen.fullscrened path.enterfullscreen{
	fill: #7b562200;
} 
button.fullscreen.fullscrened path.exitfullscreen {
	fill: var(--allMainSVGsFill);
}
/* svg.currentIntersected path.shadowOne {
	animation: svgCurrentIntersectedOne 2.5s infinite;
}
@keyframes svgCurrentIntersectedOne {
			0% {
				-webkit-filter: drop-shadow(0 0 0rem var(--locationButtonShadow));
				filter: drop-shadow(0 0 0rem var(--locationButtonShadow));
			}
	
			50% {
				-webkit-filter: drop-shadow(0 0 1rem var(--locationButtonShadow));
				filter: drop-shadow(0 0 1rem var(--locationButtonShadow));
			}
	
			100% {
				-webkit-filter: drop-shadow(0 0 0rem var(--locationButtonShadow));
				filter: drop-shadow(0 0 0rem var(--locationButtonShadow));
			}
	
	
	
		} */

@media (max-width: 1000px) {
	.screenIco {
		width: 5rem;
		height: 5rem;
	}
	.locationButton.hidden{
		display: block;
	}
	.wrapper_container{
		/* display: none; */
		position: relative;
	}
	
	.void{
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(255, 255, 255, 0);
	}
	.void.hidden{
		display: none;
	}
	.zoomRange_wrapper.hidden{
		display: none;
	}
}
.screenIco svg{
	width: 100%;
	height: 100%;
}
 
button{
	width: 8rem;
	height: 5rem;
	padding: 0.5rem;
	/* border: 0.2rem solid var(--lightYellow); */
	outline: none;
	background-color: var(--containersBackground);
}
button svg{

	height: 100%;
}

.closeIcon.delayed{
	opacity: 0;
	z-index: -1;
	cursor: default;
}
.closeIcon {
	opacity: 1;
	z-index: 1;
	cursor: pointer;
	transition: 0.3s opacity ease
}
.zoom_wrapper{
	z-index: 0;
}

.hints-wrapper {
	font-family: 'Kameron', serif;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: #04090dbd;
	/* z-index: 10; */
	color: var(--yellow);
	transition: opacity 1.5s ease;
}

.hints-wrapper h1 {
text-transform: uppercase;
text-align: center;
margin-top: 1.6rem;
letter-spacing: 0.2rem;
color: #945C1F;
font-size: 2.2rem;
}
.hints-container{
	height: 100%;
	width: 100%;

}
.closeHints{
	position: fixed;
	/* background-color: rgba(255, 253, 253, 0); */
	/* border: none; */
	/* outline: none; */
	/* height: auto; */
	/* width: auto; */
	top: 2rem;
	right: 2.5rem;
	cursor: pointer;
	/* z-index: 50000; */

}
.closeHints svg{
	height: 2rem;
	width: 2rem;
	cursor: pointer;
}

.hint{
	width: 100%;
	height: 100%;
	/* background-color: red; */
}
.fullscren-info-container{
	position: absolute;
	left: 7rem;
	top: 50%;
	transform: translateY(-50%);
}
.hints-wrapper h3{
letter-spacing: 0.1rem;
font-size: 1.2rem;
}

.rangeBar-info-container{
	position:absolute;
	right: 7rem;
	top: 50%;
	transform: translateY(-50%);
}
.zoomInOut-info-container{
	position: absolute;
	bottom: 7rem;
	left: 50%;
	transform: translateX(-50%);
}

.countryClick-info-container{
	text-align: center;
	width: 50%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform:translate(-50%, -50%);
}
.countryClick-info-container .hintFour-img{
	display: inline-block;
	width: 22rem;
	margin-top: 2rem;
	opacity: 0.9;
	/* border: 0.1rem solid var(--containersBackground); */
	box-shadow: 0rem 0rem 3rem  #945C1F;
}
.countryClick-info-container .hintFour-img img{
	width: 100%;
}
.countryClick-info-container h3 span{
	font-size: 1.6rem;
	display: block;
	color: #945C1F;
}
.countrySearcher-info-container h3 div,
.countryClick-info-container h3 div{
	color: #aa6c29;
	font-size: 1rem;
}
.countrySearcher-info-container{
	text-align: center;
	width: 50%;
	position: absolute;
	top: 7rem;
	left: 50%;
	transform: translateX(-50%);
}

.hintsProgress-container{
	font-family: 'Kameron', serif;
	color: #945C1F;
	z-index: 1000;
	position: absolute;
	right: 1.5rem;
	bottom: 1.5rem;
	width: 10rem;
	height: 5rem;
}
.slider-count-container{
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	text-align: center;
	
}
.slider-count-container .slider-count{
	/* text-align: center; */
	margin-bottom: 0.5rem;
	font-weight: 700;
	font-size: 1.3rem;
	letter-spacing: 0.1rem;
}
.slide-nav-buttons {
	display: flex;
	color: var(--yellow);
	justify-content: space-around;
	
}
.slide-nav-buttons button{
	width: auto;
	height: auto;
	color: #945C1F;
	border: 0.2rem solid #945C1F;
	font-size: 2rem;
	padding: 0rem 1rem 0.25rem;
	cursor: pointer;
}
@media (max-width: 1000px) {
	/* .hints-wrapper.hidden {
		display: block;
		position: fixed;
		top: -100vh;
		left: 0;
		opacity: 0;
		transition: all 1.5s ease;
	}
	.hints-wrapper {
		position: fixed;
		top: 0%;
		left: 0;
		opacity: 1;
		transition: all 1.5s ease;
	} */
	.zoom_wrapper.hinted {
		padding: 0.85rem 0 8rem;
		/* margin-bottom: 5rem; */
		/* background-color: #ffffff00; */
	}
	.hintsProgress-container {
		
		left: 50%;
		transform: translateX(-50%);
		bottom: 1rem;
		width: 100%;
		padding-top: 1.1rem;
		height: auto;
		border-top: 0.2rem solid #945C1F;
	}
	.slide-nav-buttons button {
		color: #945C1F;
		border: 0.2rem solid #945C1F;
		font-size: 3rem;
		padding: 0rem 2rem 0.3rem;
		cursor: pointer;
	}
	.slide-nav-buttons{
		padding: 0 2rem;
		justify-content: space-around;
		align-items: center;
	}
	.slider-count-container .slider-count {
		/* text-align: center; */
		/* margin-bottom: 0.5rem; */
		/* margin-top: 1rem; */
		font-size: 1.4rem;
		letter-spacing: 0rem;
	}


		
	
		.fullscren-info-container {
			position: absolute;
			left: 50%;
			width: 90%;
			height: 5rem;
			text-align: center;
				/* height: auto; */
			top: auto;
			bottom: 45%;
			transform: translateX(-50%);
		}
	
		.hints-wrapper h3 {
			letter-spacing: 0.1rem;
			font-size: 1.5rem;

		}
	
		.rangeBar-info-container {
			position: absolute;
			right: auto;
			left: 2rem;
			width: 80%;
			top: 50%;
			transform: translateY(-50%);
		}
		.zoom_container.hinted{
			padding: 1rem 0 5rem 0;
		}
		.zoomInOut-info-container {
			position: absolute;
			bottom: 45%;
			width: 90%;
			left: 50%;
			text-align: center;
			transform: translateX(-50%);
		}
	
		.countryClick-info-container {
			text-align: center;
			width: 90%;
			position: absolute;
			top: 45%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	
		.countryClick-info-container .hintFour-img {
			display: inline-block;
			width: 80%;
			margin-top: 1.5rem;
			opacity: 0.9;
			/* border: 0.1rem solid var(--containersBackground); */
			box-shadow: 0.1rem 0.1rem 0.6rem #945C1F;
		}
	
		.countryClick-info-container .hintFour-img img {
			width: 100%;
		}
	
		.countryClick-info-container h3 span {
			font-size: 2.2rem;
			display: block;
			color: #945C1F;
		}
	
		.countrySearcher-info-container {
			text-align: center;
			width: 90%;
			position: absolute;
			top: 13rem;
			left: 50%;
			transform: translateX(-50%);
		}


				.hints-wrapper h1 {
					margin-top: 1.6rem;
					letter-spacing: 0.2rem;
					color: #945C1F;
					font-size: 2.2rem;
					width: 100%;
					padding-bottom: 1rem;
					border-bottom: 2px solid #945C1F;
				}
		
		
				.closeHints {
					position: fixed;
					/* background-color: rgba(255, 253, 253, 0); */
					/* border: none; */
					/* outline: none; */
					/* height: auto; */
					/* width: auto; */
					top: 5.8rem;
					right: 0rem;
					cursor: pointer;
					height: 5rem;
					width: 5rem;
					/* background-color: red; */
					display: flex;
					justify-content: center;
					align-items: center;
					/* z-index: 50000; */
					
		
				}
		
				.closeHints svg {
					height: 2rem;
					width: 2rem;
					cursor: pointer;
				}
}


.hints-popup-wrapper{
	font-family: 'Kameron', serif;
	color: var(--yellow);
	position: fixed;
	top: 0;
	left:0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #04090d00;	
	transition: background-color 0.5s ease;
	z-index: -1;
}

.hints-popup-wrapper.popped {
	background-color: #04090dbd;
	transition: background-color 1s ease;
	z-index: 100;
}

.hints-popup-wrapper.hidden {
	display: none;
}

.hints-popup-container{

	position: relative;
	padding: 0rem 0 1.7rem;
	background-color: var(--containersBackground);
	border: 0.15rem solid var(--buttonBorders);
	width: 35rem;
	z-index: -1;
	scale: 0.01;
	transition: scale 0.5s ease;
}
.hints-popup-container.popped {
	scale: 1;
	z-index: 100;
	transition: scale 1s ease;
}
.hints-popup-container .popup-header p{
	font-size: 1.1rem;
	width: 80%;
	margin: 0 auto;

}
 button{
	transition: background-color 0.3s;
} 
button:hover{
	transition:background-color 0.3s;
	/* background-color: #141e25; */
} 

.hints-popup-container .popup-header h2{
	position: relative;
	letter-spacing: 0.1rem;
	font-size: 2rem;
	margin-bottom: 1.5rem;
	padding: 0.7rem 0 0.7rem 10%;
	color: var(--locationButtonSVGFill);
	text-align: left;
	width: 100%;
	border-bottom: 0.1rem solid var(--buttonBorders);
}
.hints-popup-container .closePopup {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 50%;
	height: 100%;
	width: 4rem;
	width: height;
	transform: translateY(-50%);
	right: 0rem;
	cursor: pointer;
}

.hints-popup-container .closePopup svg {
	width: 1.5rem;
	height: 1.5rem;

}
.hints-popup-container .closePopup svg path {
	fill: var(--buttonBorders);
}
.hints-popup-container .buttons-container{
	width: 80%;
	margin: 1rem auto 0;
}
.hints-popup-container .buttons-container h3{
	font-size: 1.5rem;
	color:var(--locationButtonSVGFill);
	margin-bottom: 1rem;
}
.hints-popup-container .buttons-container button{
	width: auto;
	height: auto;
	color: var(--yellow);
	border: 0.1rem solid var(--allMainSVGsFill);
	font-family: 'Kameron', serif;
	cursor: pointer;
	letter-spacing: 0.05rem;
	font-size: 1.1rem;
	padding: 0.5rem 1rem;
	background-color: transparent;
}
.hints-popup-container .buttons-container button:hover {
	background-color: var(--backgroundButtonContainersHover);
	transition: all 0.3s ease;
}
.hints-popup-container .buttons-container button.no {
	margin-left: 1rem;

}
@media (max-width: 1000px) {

		
	
		.hints-popup-container {
			padding: 0rem 0 1.7rem;
			width: 95%;
		}
	
		.hints-popup-container .popup-header p {
			font-size: 1.5rem;
			width: 90%;
			margin: 1rem auto;
	
		}
	
	

	
		.hints-popup-container .closePopup {
			position: absolute;
			/* top: 0.7rem; */
			width: 4rem;
			right: 0rem;
			/* padding: 0.5rem; */
		}
	
		.hints-popup-container .closePopup svg {
			width: 2rem;
			height: 2rem;
	
		}
	
		.hints-popup-container .popup-header h2 {
			font-size: 2rem;
			margin-bottom: 2.5rem;
			padding:1rem 0 1rem 5%;
			text-align: left;
			width: 100%;
			border-bottom: 0.1rem solid #945C1F;
		}
	
		.hints-popup-container .buttons-container {
			width: 90%;
			margin: 1rem auto 0;
		}
	
		.hints-popup-container .buttons-container h3 {
			font-size: 2rem;
			margin-bottom: 1rem;
		}
	
		.hints-popup-container .buttons-container button {
			display: block;
			letter-spacing: 0.05rem;
			font-size: 1.5rem;
			padding: 1rem 1.5rem;
			
			background-color: transparent;
		}
	
		.hints-popup-container .buttons-container button:hover {
			background-color: #141e25;
			transition: all 0.3s ease;
		}
	
		.hints-popup-container .buttons-container button.no {
			margin-left:0rem;
			margin-top: 1rem;
	
		}
}

.zoom_wrapper.hidden {
	display: none;
}



.landscape-popup_wrapper{

	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: #1f1100d8;
	z-index: 100;
}

.landscape-popup_container{
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	color: #8a6736;
	width: 80%;
	font-size: 1rem;
	text-align: center;
}
.landscape-popup_container h1{
	margin-bottom: 2rem;
}


.noteIcon_container{
	/* position: fixed; */
	padding: 0;
	/* top: 0.8rem; */
	/* left: 1.5rem; */
}

.noteIcon_container .noteIcon_button{
	width: 3.5rem;
	cursor: pointer;
	border: 0.2rem solid var(--buttonBorders);
	padding: 0rem;
	height: auto;
	display: flex;
	align-items: center;
	justify-content: center;
}
.noteIcon_container .noteIcon_button svg path {
	fill: none;
	-webkit-filter: drop-shadow(0 0 2rem #693a00);
	filter: drop-shadow(0 0 2rem #693a00);
	transition: all ease 0.3s;
}
.noteIcon_container.active .noteIcon_button svg path{
	fill: none;
	/* animation: noteAnimation 5s infinite; */
	transition: all ease 0.3s;
}
.noteIcon_container .noteIcon_button svg{
	height: 3rem;
	width: auto;
	text-align: center;
	justify-items:center ;
}

.noteIcon_container.active .noteIcon_button svg .last-path {
	stroke:var(--allMainSVGsFill);
	stroke-width: 1.5rem;
	transition: all ease 0.3s;
}
.noteIcon_container .noteIcon_button svg .last-path {
	stroke: #693a00;
	stroke-width: 1.5rem;
	transition: all ease 0.3s;
}
.noteIcon_container.hidden{
	display: none;
}
@media (max-width: 1000px) {
	.noteIcon_container{
		display: inline-block;
	}
	.noteIcon_container .noteIcon_button {
			height: 5rem;		
			width: 5rem;
			cursor: pointer;
			border: 0.2rem solid var(--buttonBorders);
			padding: 0rem;
			height: auto;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: var(--backgroundButtonContainers) ;
		}
		
		.noteIcon_container .noteIcon_button svg {
			height: 4.6rem;
			width: auto;
			text-align: center;
			justify-items: center;
		}
	
		.noteIcon_container.active .noteIcon_button svg .last-path {
			stroke: var(--allMainSVGsFill);
			stroke-width: 1.5rem;
			transition: all ease 0.3s;
		}
	
		.noteIcon_container .noteIcon_button svg .last-path {
			stroke: #693a00;
			stroke-width: 1.5rem;
			transition: all ease 0.3s;
		}
}





.screenStatus .forMob {
	display: none;
}

@media (max-width: 1000px) {
	.screenStatus{
		height: 5rem;

	}
	.screenStatus .forMob {
		display: inline-block;
	}

	.screenStatus .forPc {
		display: none;
	}
}


/* burger menu seection */
.menu-wrapper{
	opacity: 0;
	background-color: var(--containersBackground);
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	z-index: 900;
	left: -101vw;
	transition: all 0.5s ease;
	color: var(--countryStatsHeaderColor);
	overflow-y: scroll;
	scrollbar-color: var(--countryStatsHeaderColor) var(--containersBackground);
}
.menu-wrapper.active{
	opacity: 1;
	left: 0;
	transition: all 0.5s ease;
}
.closeMenuIcon{
	position: absolute;
	top: 2rem;
	right: 2rem;
	padding: 0;
	background-color: transparent;
	border: none;
	outline: none;
	width: 2rem;
	height: 2rem;
	cursor: pointer;

}
.closeMenuIcon svg {
	height: 100%;
	width: 100%;
}
.closeMenuIcon svg path{
	fill: var(--buttonBorders);
}
.menu-container{
	padding-top: 2rem;
	width: 80%;
	margin: 0 auto;
}
.menu_header{
	width: 100%;
	margin: 0 auto;
	border-bottom: 0.2rem solid var(--buttonBorders);
	text-align: center;
	padding-bottom: 1rem;
}
.menu_header h1 {
 color: var(--buttonBorders);
 text-transform: uppercase;
 letter-spacing: 0.3rem;
 font-size: 2rem;
}
.menu_section{
	margin-top: 2rem;	
}
.menu_section .sectionHeader{
	font-size: 1.8rem;
color: var(--buttonBorders);
margin-bottom: 1.5rem;
}
.menu_section .menuInstructionButton {
	cursor: pointer;
	border: 0.2rem solid var(--buttonBorders);
	padding: 0.75rem 2rem;
	color: inherit;
	height: auto;
	width: auto;
	font-size: 1.2rem;
	letter-spacing: 0.1rem;
	margin-top: 1rem;
}
.menu_section {
	padding-bottom: 2rem;
	border-bottom: 0.2rem solid var(--buttonBorders);
}
.noteIcon_container{
	margin-top: 1rem;
}
.resourcesSection .sectionBody h3{
	color:var(--countryStatsHeaderColor);
	font-weight: 300;
	margin-bottom: 1rem;
}
.resourcesSection .sectionBody a{
	color: var(--countryStatsHeaderColor);
}
.menu_footer{
	margin: 1rem 0 1rem;
	text-align: center;
}
.menu_footer a {
	color: var(--countryStatsHeaderColor);
}


@media (max-width: 1000px) {
		.menu-container {
			padding-top: 1.7rem;
			width: 90%;
		}

		.closeMenuIcon {
			top: 1.9rem;
			right: 1.5rem;
			width: 2.5rem;
			height: 2.5rem;
		}

		.menu_header h1 {
			font-size: 2.6rem;
		}

		.menu_section .sectionHeader {
			font-size: 2.3rem;
		}

		.menu_section p{
			font-size: 1.2rem;
		}

		.menu_section .menuInstructionButton {
			padding: 0.75rem 2rem;
			font-size: 1.5rem;
			margin-top: 1.5rem;
		}

		.noteIcon_container {
			margin-top: 1.5rem;
		}

		.menu_footer {
			font-size: 1.3rem;
		}
}
/* animations */
@keyframes svgCurrentIntersected {
	0% {
		-webkit-filter: drop-shadow(0 0 0rem var(--locationButtonShadow));
		filter: drop-shadow(0 0 0rem var(--locationButtonShadow));
	}

	50% {
		-webkit-filter: drop-shadow(0 0 11rem var(--locationButtonShadow));
		filter: drop-shadow(0 0 11rem var(--locationButtonShadow));
	}

	100% {
		-webkit-filter: drop-shadow(0 0 0rem var(--locationButtonShadow));
		filter: drop-shadow(0 0 0rem var(--locationButtonShadow));
	}
}


/* @keyframes noteAnimation{
	0% {
		-webkit-filter: drop-shadow(0 0 0rem var(--locationButtonShadow));
		filter: drop-shadow(0 0 0rem var(--locationButtonShadow));
	}

	50% {
		-webkit-filter: drop-shadow(0 0 2rem var(--locationButtonShadow));
		filter: drop-shadow(0 0 2rem var(--locationButtonShadow));
	}

	100% {
		-webkit-filter: drop-shadow(0 0 0rem var(--locationButtonShadow));
		filter: drop-shadow(0 0 0rem var(--locationButtonShadow));
	}
} */
	

